import { render, staticRenderFns } from "./AboutUsPage.vue?vue&type=template&id=7398a455&scoped=true&"
import script from "./AboutUsPage.vue?vue&type=script&lang=js&"
export * from "./AboutUsPage.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7398a455",
  null
  
)

export default component.exports