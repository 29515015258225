var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(this.alternativePageContent.contactHtml)?_c('div',{domProps:{"innerHTML":_vm._s(this.alternativePageContent.contactHtml)}}):_c('div',{staticClass:"container"},[_c('h1',{staticClass:"underlined"},[_vm._v("Kontakt")]),_vm._m(0),_c('p',[_vm._v("Aplikaci neustále zlepšujeme, aby vám přinesla ještě více užitku. Proto budeme rádi, když se s námi podělíte o své nápady, postřehy či připomínky a uděláme vše proto, aby pro vás byl Cityvizor tím nejlepším řešením.")]),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_c('h2',[_vm._v("Partner aplikace")]),_vm._m(7),_c('ParticipateBox')],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v("V případě potřeby se na nás obraťte na "),_c('a',{attrs:{"href":"mailto:cityvizor@otevrenamesta.cz"}},[_vm._v("cityvizor@otevrenamesta.cz")]),_vm._v(", nebo na "),_c('a',{attrs:{"href":"mailto:cityvizor@operatorict.cz"}},[_vm._v("cityvizor@operatorict.cz")]),_vm._v(", pokud se dotaz týká Prahy a jejich městských částí.")])
},function (){var _vm=this,_c=_vm._self._c;return _c('h2',[_vm._v("Provozovatel aplikace na doméně "),_c('a',{attrs:{"href":"https://cityvizor.cz/"}},[_vm._v("cityvizor.cz")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"https://www.otevrenamesta.cz/"}},[_c('img',{staticClass:"logo",attrs:{"src":require("@/assets/images/om_logo.png"),"width":"200px"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('a',{attrs:{"href":"https://www.otevrenamesta.cz/"}},[_vm._v("Otevřená města, z. s.")]),_c('br'),_c('a',{attrs:{"href":"https://www.openstreetmap.org/#map=18/49.24064/16.57369"}},[_vm._v("Hudcova 239/7, Medlánky")]),_c('br'),_vm._v(" 621 00 Brno ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('h2',[_vm._v("Provozovatel aplikace na doméně "),_c('a',{attrs:{"href":"https://cityvizor.praha.eu/"}},[_vm._v("cityvizor.praha.eu")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"https://operatorict.cz/"}},[_c('img',{staticClass:"logo",attrs:{"src":require("@/assets/images/OICT.jpg"),"width":"150px"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('a',{attrs:{"href":"https://operatorict.cz/"}},[_vm._v("Operátor ICT, a.s.")]),_c('br'),_c('a',{attrs:{"href":"https://www.openstreetmap.org/#map=19/50.10290/14.44617"}},[_vm._v("Dělnická 213/12")]),_c('br'),_vm._v(" 170 00 Praha 7 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"https://cesko.digital/"}},[_c('img',{staticClass:"logo",attrs:{"src":require("@/assets/images/cesko-digital_logo.png"),"width":"150px"}})])
}]

export { render, staticRenderFns }