import { render, staticRenderFns } from "./Home.vue?vue&type=template&id=4b9de082&"
import script from "./Home.vue?vue&type=script&lang=js&"
export * from "./Home.vue?vue&type=script&lang=js&"
import style0 from "./Home.vue?vue&type=style&index=0&id=4b9de082&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)


    import installComponents from "!/user/src/app/node_modules/bootstrap-vue-loader/lib/runtime/installComponents.js"
    import {BCol, BRow, BCardText, BCardBody, BCard, BContainer} from 'bootstrap-vue'
    installComponents(component, {BCol, BRow, BCardText, BCardBody, BCard, BContainer})
    

export default component.exports